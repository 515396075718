import { useContext, useEffect } from "react";
import HomePageContext from "../context/HomepageContext";
import AddressContext from "../context/AddressContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import CartContext from "../context/CartContext";
import {
  getIPInfo,
  handleDefaultLocationFetch,
} from "../functions/location/handleLocation";
import CompRenderingContext from "../context/CompRenderingContext";
import { v2Api } from "../api/v2.api";
import { isBrowser, isMobile } from "react-device-detect";

const useFetchEboData = () => {
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { handleCartPresence } = useContext(CartContext);
  const { fetchAddressData } = useContext(AddressContext);
  const { offerState, getOffers } = useContext(OfferContext);
  const { setLocationData, locationData } = useContext(CompRenderingContext);

  useEffect(() => {
    async function callMethods() {
      const ipInfo = await handleUserLogin();
      const hubId = await handleDefaultLocationFetch(
        setLocationData,
        locationData,
        ipInfo
      );
      hubId && (await fetchHomeData({ hubId, d: "first" }));
      if (user) {
        await fetchAddressData();
        await handleCartPresence();
      }
      if (offerState.offers.length === 0 || !offerState.offers) {
        await getOffers();
      }
    }
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  const handleUserLogin = async () => {
    try {
      let ipInfo = await getIPInfo(); // will also handle the traffic loggin

      const options = {
        tags: ["user", "userLogin"],
        data: {
          registered: user?.id ? true : false,
          ipInfo: ipInfo,
          userId: user?.id,
          device: isMobile ? "mobile" : isBrowser ? "browser" : null,
          src: "site",
        },
      };

      v2Api(options);

      return ipInfo;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData &&
        locationData?.selectedLocation?.hubId &&
        (await fetchHomeData({
          hubId: locationData?.selectedLocation?.hubId,
          d: "second",
        }));
    }
    callMethods();
    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.hubId]);

  return;
};

export default useFetchEboData;
