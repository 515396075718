import React, {
  useContext,
  useEffect,
  memo,
  useRef,
  useState,
  Suspense,
} from "react";
import { useSearchParams } from "react-router-dom";
// --------- components imports --------------------------------
import PreviewHeaderLg from "../../components/productPreview/PreviewHeaderLg";
import SortAndFilter from "../../components/productPreview/SortAndFilter";
import Filter from "../../components/productPreview/subComponents/Filter";
import SortModal from "../../components/modals/SortModal";
import FilterModal from "../../components/modals/FilterModal";
import Header from "../../assets/Header";
import FloatingWhatsapp from "../../assets/whatsappModal/FloatingWhatsapp";
import moment from "moment";
import CalendarPop from "../../assets/calendar/CalendarPop";
import SubCategories from "./subCategories/SubCategories";
import ProductListBanner from "../../components/productPreview/ProductListBanner";
import { getSubCategoriesList } from "./helperFn";
import { useSpring, motion, AnimatePresence } from "framer-motion";
// --------- css imports --------------------------------
import "./styles/previewproduct.css";
import "./styles/animation.css";
// --------- context imports --------------------------------
import ProductPreviewContext from "../../context/ProductPreviewContext";
import TempAuthContext from "../../context/TempAuthContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import GLobalStoreContext from "../../context/GlobalStoreContext";
import HomePageContext from "../../context/HomepageContext";
import CompRenderingContext from "../../context/CompRenderingContext";
import { useScrollDirection } from "../../hooks/useScrollDirection";

const ProductList = React.lazy(() =>
  import("../../components/productPreview/ProductList")
);

const ProductListingPagev2 = () => {
  const pageContainer = useRef(null);
  const { userStates } = useContext(GLobalStoreContext);
  const [queries] = useSearchParams();
  let filter = queries.get("filter");

  if (!filter) {
    filter = { category: 0 };
  } else {
    filter = JSON.parse(filter);
  }

  const isCategoryListingPage = typeof parseInt(filter.category) === "number";
  const { createTempUser } = useContext(TempAuthContext);
  const { homeInitialData, getDecorOccassionCategoryName } =
    useContext(HomePageContext);

  const [sortParams] = useSearchParams();
  const {
    previewProducts: { previewProductData, isLoading },
    currPage,
    setCurrPage,
    getProductsByFilter,
    setCurrUserPos,
    totalProductsCount,
    productListingpageBanners,
  } = useContext(ProductPreviewContext);

  const { screenWidth } = useContext(ResponsiveContext);
  const { locationData } = useContext(CompRenderingContext);
  const [componentMounted, isComponentMounted] = useState(false);

  useEffect(() => {
    isComponentMounted(true);
    return () => {
      isComponentMounted(false);
    };
  }, []);

  const containerRef = useRef(null);

  const { scrollStates } = useScrollDirection(containerRef);
  const spring = useSpring(0, { stiffness: 800, damping: 100 });

  let label =
    filter?.label || getDecorOccassionCategoryName(parseInt(filter.category));
  label = label.charAt(0).toUpperCase() + label.slice(1);
  // --------------- STATES ----------------
  const [isCalendar, setIsCalendar] = useState(false);
  const [states, setStates] = useState({
    headingName: "",
    subCategories: [],
    topPadding: 0,
    eachDecorHeight: 0,
    lastCardPreviewedIndex: 0,
    arrayOfProductsWhichAreInView: [],
  });

  useEffect(() => {
    setStates((p) => ({
      ...p,
      headingName: label,
      subCategories: isCategoryListingPage
        ? getSubCategoriesList({
            homeInitialData,
            code: parseInt(filter.category),
          })
        : [],
      topPadding:
        getSubCategoriesList({
          homeInitialData,
          code: parseInt(filter.category),
        })?.length === 0
          ? 115
          : 200,
    }));
    // eslint-disable-next-line
  }, [homeInitialData]);

  useEffect(() => {
    createTempUser();
    !currPage.isLast && dataRefresh();

    if (!userStates.defaultCelebrationDate) {
      setIsCalendar(true);
    }
    // eslint-disable-next-line
  }, [sortParams, locationData?.selectedLocation?.hubId]);

  useEffect(() => {
    if (scrollStates.scrollY <= states.topPadding + 70) {
      spring.set(0);
    } else {
      if (scrollStates.scrollDir === "Up") {
        if (scrollStates.scrollDistance > 20) {
          spring.set(-states.topPadding);
        }
      } else {
        if (scrollStates.scrollDistance > 20) {
          spring.set(0);
        }
      }
    }
    // eslint-disable-next-line
  }, [scrollStates]);

  useEffect(() => {
    // Retrieve Y-axis position from session storage if available and scroll to that position
    const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
    const yPosition = savedPositions[0];

    if (yPosition && containerRef.current) {
      containerRef.current.scrollTo(0, yPosition);
      const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
      savedPositions[0] = 0;
      sessionStorage.setItem("YP", JSON.stringify(savedPositions));
    }
  }, []);

  const dataRefresh = async () => {
    await getProductsByFilter({
      filter: filter,
    });
  };

  const onProductClickCallBack = () => {
    // Store Y-axis position in session storage
    const currentPosition = containerRef.current.scrollTop;
    const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
    savedPositions[0] = currentPosition;
    sessionStorage.setItem("YP", JSON.stringify(savedPositions));
  };

  console.log("states", states.arrayOfProductsWhichAreInView);

  return (
    <div className="md:mt-20  font-['Roboto']  fixed right-0 top-0 left-0 bottom-0 overflow-scroll scroll-bar-remove">
      {/* calendar popup component */}
      {isCalendar && (
        <CalendarPop
          setIsCalendar={setIsCalendar}
          validStartingDate={moment()}
          validEndingDate={moment().add(30, "days")}
          minProductAvabilityDays={0}
        />
      )}
      <Header
        link="/"
        title={label}
        refreshAllProductPage={() => {
          setCurrPage({
            page: 0,
            isLast: false,
          });
          setCurrUserPos(0);
        }}
        productCount={totalProductsCount}
      />
      {screenWidth > 976 && (
        <PreviewHeaderLg text={label} productCount={totalProductsCount} />
      )}

      {screenWidth < 977 && (
        <motion.div
          style={{ translateY: spring }}
          className={`top-[3rem] absolute w-full  z-[10] `}
        >
          <div className="px-2 space-y-4 pt-2 items-center flex flex-col  bg-gradient-to-b from-blue-300 to-white ">
            <ProductListBanner
              productListingpageBanners={productListingpageBanners}
            />
            <SubCategories
              categoryCode={parseInt(filter.category)}
              subCategoriesList={states.subCategories}
              decorCategoriesList={homeInitialData.decorCategoriesList}
            />
          </div>

          <SortAndFilter />

          <AnimatePresence>
            {scrollStates.isScrolling && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className=" z-[11] bottom-[-2.5rem] text-[.875rem] opacity-70 absolute w-full flex items-center justify-center"
              >
                <div className="rounded-full px-6 py-1 bg-[#262626c9] text-white">
                  <span className="mr-2">DECOR DESIGN</span>{" "}
                  {states.lastCardPreviewedIndex + 1}/{totalProductsCount}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}

      <AnimatePresence>
        {scrollStates.isScrolling && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className=" z-[11] hidden md:flex top-[4rem] text-[.875rem] opacity-70 absolute w-full items-center justify-center"
          >
            <div className="rounded-full px-6 py-1 bg-[#262626c9] text-white">
              <span className="mr-2">DECOR DESIGN</span>{" "}
              {states.lastCardPreviewedIndex + 1}/{totalProductsCount}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {isLoading && currPage.page === 0 && (
        <ProductSkeletons topPadding={states.topPadding} />
      )}

      <motion.div
        ref={containerRef}
        className={`${
          isLoading && currPage.page === 0 && " opacity-0 "
        } fixed ${
          screenWidth < 977 ? "top-[3.5rem]" : "top-[-6rem]"
        } bottom-0 left-0 right-0  overflow-y-scroll  scroll-bar-remove`}
      >
        <Filter />
        <div
          style={{
            marginTop: screenWidth < 977 ? states.topPadding + 70 + "px" : 0,
          }}
        >
          {componentMounted ? (
            <Suspense fallback={<div></div>}>
              <ProductList
                dataRefresh={dataRefresh}
                text={filter.category}
                setCurrUserPos={setCurrUserPos}
                pageContainer={pageContainer}
                products={previewProductData}
                inPreviewCardCallBack={(cardIndex) => {
                  const minIndex = screenWidth < 977 ? 3 : 2;
                  if (
                    scrollStates.scrollDir === "Up" &&
                    states.lastCardPreviewedIndex < cardIndex
                  ) {
                    setStates((p) => ({
                      ...p,
                      lastCardPreviewedIndex:
                        minIndex < cardIndex ? cardIndex : minIndex,
                    }));
                  } else if (
                    scrollStates.scrollDir === "Down" &&
                    states.lastCardPreviewedIndex > cardIndex
                  ) {
                    setStates((p) => ({
                      ...p,
                      lastCardPreviewedIndex:
                        minIndex < cardIndex ? cardIndex : minIndex,
                    }));
                  }
                }}
                setProductListingPageStates={setStates}
                onProductClickCallBack={onProductClickCallBack}
              />
            </Suspense>
          ) : (
            <div>Fetching decors...</div>
          )}
        </div>
      </motion.div>
      <SortModal />
      <FilterModal />
      <FloatingWhatsapp pageName={"products"} />
    </div>
  );
};
const ProductSkeletons = ({ topPadding }) => {
  const skeletonsCountArray = new Array(14).fill(0);
  return (
    <div
      style={{
        rowGap: ".875rem",
        paddingTop: topPadding + 110 + "px",
      }}
      className="flex w-full  scroll-bar-remove fixed top-0 right-0 left-0 bottom-0 flex-wrap justify-evenly overflow-y-scroll "
    >
      {skeletonsCountArray.map((_, i) => {
        return (
          <div
            style={{
              width: "47%",
              minWidth: "47%",
            }}
            key={i}
            className={` rounded-lg  h-[14rem] ebo_skeleton `}
          ></div>
        );
      })}
    </div>
  );
};
export default memo(ProductListingPagev2);

/**
 
one row  =  315
 */
